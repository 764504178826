import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/assignation',
    name: 'Assignation',
    // route level code-splitting
    // this generates a separate chunk (connectivity.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "assignation" */ '../views/Assignation.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/sale',
    name: 'Sale',
    // route level code-splitting
    // this generates a separate chunk (connectivity.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sale" */ '../views/Sale.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/validation',
    name: 'Validation',
    // route level code-splitting
    // this generates a separate chunk (connectivity.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "validation" */ '../views/Validation.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/support',
    name: 'Support',
    // route level code-splitting
    // this generates a separate chunk (connectivity.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (connectivity.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {login: true}
  },
  { path: "*", 
  component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'), 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)){ 
    if(auth.currentUser || sessionStorage.getItem('user')){
      next()
    }else {next({name: 'Login'})}
  }else {
    if(to.meta.login && (sessionStorage.getItem('user') || auth.currentUser )){next({name: 'Home'})}
    else{next()}
  } 
})

export default router
