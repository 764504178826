import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import {db} from '../firebase'
import {ref, onValue, update, query, orderByChild} from "firebase/database";
import {auth} from "../firebase"
import {signInWithEmailAndPassword, signOut, sendPasswordResetEmail} from "firebase/auth";
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    error: null,
    catalog: [],
    pixels: [],
    sold: [],
    fittingRoom: []
  },

  mutations: {
    SET_USER(state, payload){
      state.user=payload
    },
    SET_ERROR(state, payload){
      state.error=payload
    },
    SET_CATALOG(state, payload){
      state.catalog=payload
    },
    SET_PIXELS(state, payload){
      state.pixels=payload
    },
    SET_SOLD(state, payload){
      state.sold=payload
    },
    SET_FITTINGROOM(state, payload){
      state.fittingRoom=payload
    }
  },

  actions: {
    async logIn({commit}, userLogin){
      try {
        const userSnapshot = await signInWithEmailAndPassword(auth, userLogin.email, userLogin.password)
        commit('SET_USER', userSnapshot.user)
        commit('SET_ERROR', null)
        sessionStorage.setItem('user', JSON.stringify(userSnapshot.user))
        router.push('/')
      } catch (error) {
        let message=null
          if(error.code==='auth/user-not-found'){message='Usuario no encontrado'}
          if(error.code==='auth/wrong-password'){message='Contraseña incorrecta'}
          commit('SET_ERROR', message)
      }
    },

    async logOut({commit}){
      try {
        sessionStorage.removeItem('user')
        const resp = await signOut(auth)
        router.push('/login')
        commit('SET_USER', null)
      } catch (error) {
          console.log('Code: '+error.code)
          console.log('Msg: '+error.message)
      }
    },

    async resetPassword({commit}, email){
      try {
        await sendPasswordResetEmail(auth, email)
        Swal.fire({
          icon: 'success',
          title: 'Se enviaron los pasos a seguir a tu correo para reiniciar la contraseña',
          showConfirmButton: true,
          timer: 3000
        })
      } catch (error) {
        console.log('Code: '+error.code)
        console.log('Msg: '+error.message)
      }
    },

    detectUser({commit}, user){
      commit('SET_USER', user)
    },

    async getCatalog({commit}){
      const catalogRef = ref(db, '/catalog')
      onValue(catalogRef, snapshot => {
        commit('SET_CATALOG', Object.values(snapshot.val()))
      })
    },

    async getPixels({commit}){
      const pixelsRef = ref(db, '/pixels')
      onValue(pixelsRef, snapshot => {
        commit('SET_PIXELS', Object.values(snapshot.val()))
      })
    },

    async getSold({commit}){
      const soldRef = ref(db, '/sold')
      onValue(soldRef, snapshot => {
        commit('SET_SOLD', Object.values(snapshot.val()))
      })
    },

    async getFittingRoom({commit}){
      const fitRef = ref(db, '/fittingRoom')
      //const fitRef = query(fitRef(db, fittingRoom), orderBy('created'))
      onValue(fitRef, snapshot => {
        //console.log(snapshot.val())
        commit('SET_FITTINGROOM', Object.values(snapshot.val()))
      })
    },


    },

  modules: {
  },

  getters: {
    userIsAuth(state){
      if(state.user != null){return true}
      else{return false}
    },
  }


})
