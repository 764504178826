import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// DEV Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpV6iPMGSQckR0OEykh9haS941ya3VQEs",
  authDomain: "tucane-smartinventory-dev.firebaseapp.com",
  databaseURL: "https://tucane-smartinventory-dev-default-rtdb.firebaseio.com",
  projectId: "tucane-smartinventory-dev",
  storageBucket: "tucane-smartinventory-dev.appspot.com",
  messagingSenderId: "984238645153",
  appId: "1:984238645153:web:9a3c79365c4a0b44a3f5e0"
};

// PRODUCTION Configuration
/* const firebaseConfig = {
  apiKey: "AIzaSyBoEIxRIFkPWx83XAXdBlIDFGOTT83dWpk",
  authDomain: "tucane-smartinventory-prod.firebaseapp.com",
  databaseURL: "https://tucane-smartinventory-prod-default-rtdb.firebaseio.com",
  projectId: "tucane-smartinventory-prod",
  storageBucket: "tucane-smartinventory-prod.appspot.com",
  messagingSenderId: "463046173776",
  appId: "1:463046173776:web:76d0db3282290583cc9d55"
}; */

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const auth = getAuth(app);

export {db,auth}